<template>
    <v-row class="fill-height">
        <v-col>
            <div class="d-flex fill-height flex-column justify-center align-center">
                <v-card class="pa-3 mb-1 loginpanel" tile height="150px">
                    <v-img src="@/assets/oryl_autoteile_logo.jpg" height="100%" contain/>
                </v-card>
                <v-card class="loginpanel pa-3" tile>
                    <form @submit.prevent="login">
                        <v-text-field label="Benutzername" autofocus v-model="username"/>
                        <v-text-field type="password" label="Passwort" v-model="password"/>
                        <v-btn type="submit" color="primary" tile block :loading="loading" :disabled="loading">
                            <v-icon>mdi-login</v-icon>
                        </v-btn>
                    </form>
                </v-card>
                <v-alert v-if="error" color="red" dark dense class="loginpanel mt-1 d-flex justify-center" tile elevation="2">
                    {{error}}
                </v-alert>
            </div>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "Login",
        data: () => ({
            username: null,
            password: null,
            loading: false
        }),
        computed: {
            error: function () {
                return this.$store.state.LoginStore.error;
            }
        },
        methods: {
            login: function () {
                this.loading = true;
                this.$store.commit('LoginStore/setError', null);

                this.$store.dispatch('LoginStore/login', {username: this.username, password: this.password, language: this.$i18n.locale})
                .then(() => {
                    this.$router.push('/home');
                })
                .catch(() => {
                    this.$store.commit('LoginStore/setError', this.$t('login.failed'));
                })
                .finally(() => {
                    this.loading = false;
                })
            }
        }
    }
</script>

<style scoped>
    .loginpanel {
        max-width: 400px;
        width: calc(100% - 5em);
    }

    * {
        user-select: none;
    }
</style>
